import { PitchforkCanvasSlider as ps, PitchforkCanvasSlider } from "../js/pitchfork-canvas-slider/pitchfork-canvas-slider.umd";
import Modernizr = require("../js/modernizr-custom.js");
import { ContactFormModule as cf } from "./contact";

/*import EmblaCarousel from "../../node_modules/embla-carousel/lib/index.js";
import { setupPrevNextBtns } from '../js/prevAndNextButtons';
import { setupDotBtns, generateDotBtns, selectDotBtn } from '../js/dotbuttons';
*/

let sliderCreator: ps.SliderCreator;
let navLogo: HTMLElement = undefined;
let navBar: HTMLElement = undefined;
let breadCrumbs: HTMLElement = undefined;
let lastIsScrolled: boolean | null = null;

function toggleClass(element: HTMLElement, elementClass: string) {
    if (element.classList.contains(elementClass)) {
        element.classList.remove(elementClass);
    } else {
        element.classList.add(elementClass);
    }
}

function getFirstElementByClass(elementClass: string): Element {
    let elements: HTMLCollectionOf<Element> = document.getElementsByClassName(elementClass);
    if (elements.length > 0) {
        return elements[0];
    } else {
        return undefined;
    }
}

function onBurgerClick(event: Event) {
    let target: EventTarget = event.currentTarget as EventTarget;
    let element: HTMLElement = target as HTMLElement;
    toggleClass(element, 'is-active');
    let menu: HTMLElement = getFirstElementByClass('navbar-menu') as HTMLElement;
    toggleClass(menu, 'is-active');
}

function onScroll(event: Event) {    
    let isScrolled = false;
    if (window.pageYOffset >= 2*navLogo.clientHeight) {
        isScrolled = true;
    } 
    if (lastIsScrolled == isScrolled)
        return;


    // On pages with breadcrumbs we will scroll them down fixed, otherwise
    // we'll scroll down the navbar and shrink it.
    /*if (breadCrumbs && breadCrumbs !== undefined) {
        if (isScrolled)
            breadCrumbs.classList.add('is-scrolled');
        else
            breadCrumbs.classList.remove('is-scrolled');
    } else {
        if (navLogo && navBar && navLogo !== undefined && navBar !== undefined) {
            if (isScrolled) {
                navBar.classList.add('is-scrolled');
                navLogo.classList.add('is-scrolled');
            } else {
                navLogo.classList.remove('is-scrolled');
                navBar.classList.remove('is-scrolled');
            }
        }
    } */

    lastIsScrolled = isScrolled;
}


function afterDomContentLoaded(document: HTMLDocument) {
    let element = getFirstElementByClass('navbar-burger');
    element.addEventListener('click', (event: Event) => {
        onBurgerClick(event);
    });

    let contactForm = document.getElementById("contact-form");
    if (contactForm != null && contactForm !== undefined) {
        contactForm.addEventListener('submit', (event: Event) => {
            let form = new cf.ContactForm(event.target as HTMLFormElement);
            form.Validate(event);
        });
    }


    navLogo = document.getElementById('bluevine-logo');
    //navLogo = getFirstElementByClass('bluevine-logo') as HTMLElement;
    navBar = getFirstElementByClass('navbar') as HTMLElement;
    breadCrumbs= document.getElementById('breadcrumb-level');
    window.addEventListener('scroll', (event: Event) => {
        onScroll(event);
    });

    sliderCreator = new ps.SliderCreator(document);
    sliderCreator.scanAndCreateSliders().then((result) => {
        console.log(result);
        sliderCreator.AnimateAll();
    }).catch((error: Error) => {
        console.log(error.message);
    }); 
}

function setupEmbla() {
/*
        const emblaNode: HTMLElement = document.querySelector('.embla') as HTMLElement;
        const options = { loop: true }
        const embla = EmblaCarousel(emblaNode, options)
        */

/*const options = { loop: true };
const embla = EmblemCarousel(emblaNode, options); */
            //const emblaNode: HTMLElement = document.querySelector('.embla') as HTMLElement;
/*const wrap = document.querySelector('.embla');
const viewPort: HTMLElement = wrap.querySelector('.embla__viewport') as HTMLElement;
const prevBtn = wrap.querySelector('.embla__button--prev');
const nextBtn = wrap.querySelector('.embla__button--next');
const dots = wrap.querySelector('.embla__dots');
const embla = EmblaCarousel(viewPort, { loop: true });
const dotsArray = generateDotBtns(dots, embla);
const setSelectedDotBtn = selectDotBtn(dotsArray, embla);

setupPrevNextBtns(prevBtn, nextBtn, embla);
setupDotBtns(dotsArray, embla);

embla.on('select', setSelectedDotBtn);
embla.on('init', setSelectedDotBtn); */


}


function waitForDomContentLoaded() {
    return new Promise<HTMLDocument>((resolve) => {
        if (document.readyState === 'loading') {
            window.addEventListener('DOMContentLoaded', (event: Event) => {
                console.log('DOM fully loaded and parsed');
                let target: EventTarget = event.target as EventTarget;
                let document: HTMLDocument = target as HTMLDocument;

                resolve(document);
            });
        } else {
            resolve(document);            
        }
    });
}

waitForDomContentLoaded().then((document: HTMLDocument) => {
    afterDomContentLoaded(document); 
}).catch((error: Error) => {
    console.log(error.message);
});


