
export module ContactFormModule {

    function addClass(element: Element, elementClass: string) {
        if (!element) {
            debugger;
        }
        if (!element.classList.contains(elementClass)) {
            element.classList.add(elementClass);
        }
    }

    function removeClass(element: Element, elementClass: string) {
        if (!element) {
            debugger;
        }
        if (element.classList.contains(elementClass)) {
            element.classList.remove(elementClass);
        }
    }

    function hasValue(element: HTMLFormElement): boolean {
        return (element != null && element !== undefined && element.value != null && element.value !== undefined && element.value.length > 0);
    }


    export class ContactForm {

        constructor(public form: HTMLFormElement) {
        }

        private OnResult = (request: XMLHttpRequest, ev: ProgressEvent) => {
            
        };

        private Email(name: string, email: string, subject: string, message: string) {
            var xhr = new XMLHttpRequest();
            //xhr.open('PUT', 'https://gpverdx7ue.execute-api.us-east-1.amazonaws.com/default/MyContactEmail');
            xhr.open('PUT', 'https://35lhfadf23.execute-api.us-east-1.amazonaws.com/test/contact');            
            xhr.setRequestHeader('X-BLUEVINECONTACT', '3LSLAX77D3#');
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = () => {
                if (xhr.status == 200) {
                    let successBox = document.getElementById("contact-success");
                    let contactForm = document.getElementById("contact-form");
                    removeClass(successBox, "is-hidden");
                    addClass(contactForm, "is-hidden");                    
                } else {
                    let failBox = document.getElementById("contact-fail");
                    removeClass(failBox, "is-hidden");
                }
            };
            xhr.send(JSON.stringify({
                name: name,
                email: email,
                subject: subject,
                message: message                
            }));
        }
    
        public Validate(event: Event) {
            //this.Email();
            let elements: HTMLFormControlsCollection = this.form.elements;
            let name = elements[0] as HTMLFormElement;
            let email = elements[1] as HTMLFormElement;
            let subject = elements[2] as HTMLFormElement;
            let message = elements[3] as HTMLFormElement;

            let emailWarning = document.getElementById("contact-email-help");
            let messageWarning = document.getElementById("contact-message-help");

            let successBox = document.getElementById("contact-success");
            let failBox = document.getElementById("contact-fail");
            addClass(successBox, "is-hidden");                    
            addClass(failBox, "is-hidden");                    

            let sendEmail = true;

            // contact-email-help
            if (!hasValue(email)) {
                removeClass(emailWarning, "is-hidden");
                sendEmail = false;
            } else {
                addClass(emailWarning, "is-hidden");
            }

            if (!hasValue(message)) {
                removeClass(messageWarning, "is-hidden");
                sendEmail = false;
            } else {
                addClass(messageWarning, "is-hidden");
            }

            if (sendEmail) {
                this.Email(name.value, email.value, subject.value, message.value);
            }

            event.preventDefault();
        }
    }
}


